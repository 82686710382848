<script setup lang="ts">
import { ref, computed, defineAsyncComponent } from 'vue'
import { getServerState } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import type { Link } from './types'
import type { FormattedFooterContent, FormattedFooterContentMenu } from '@/format-content'

// Components
const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then((c) => c.ZgSection))
const ZgIcon = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then((c) => c.ZgIcon))

const menuContent: FormattedFooterContent | undefined = getServerState('footer-content')

const expanded = ref<string[]>([])
const menu = computed(() => menuContent?.menu ?? [])
const emit = defineEmits<(event: 'track-navigation-click', payload: string) => void>()

const onClick = (item: Link | FormattedFooterContentMenu, index?: number) => {
  if (typeof index === 'number') {
    emit('track-navigation-click', `toggle-expanded-${item.title}`)
    toggleExpanded(index)
  } else if ('url' in item) {
    emit('track-navigation-click', item.url)
    window.location.href = item.url
  }
}

const toggleExpanded = (index: number) => {
  const name = `item-${index}`
  const expandedIndex = expanded.value.indexOf(name)

  if (expandedIndex !== -1) {
    expanded.value.splice(expandedIndex, 1)
  } else {
    expanded.value.push(name)
  }
}

const isExpanded = (index: number) => expanded.value.includes(`item-${index}`)
</script>

<template>
  <zg-section padding="large" no-padding-top :background-settings="{ backgroundColor: 'brand-100' }">
    <ul>
      <li v-for="(category, index) in menu" :key="index">
        <h4 @click="onClick(category, index)">
          {{ category.title }}

          <zg-icon icon="chevron" size="small" class="icon" :rotate="isExpanded(index) ? '180' : '0'" />
        </h4>

        <ul :class="{ 'expanded': isExpanded(index) }">
          <li v-for="(item, subIndex) in category.items" :key="subIndex">
            <a
              :href="item.url"
              :rel="item.nofollow ? 'nofollow' : ''"
              :data-tracking-title="category.title"
              :data-tracking-text="item.text"
              @click.stop="onClick(item)"
            >
              {{ item.text }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </zg-section>
</template>

<style lang="scss" scoped>
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(32px);
    padding: rem(16px) 0;
    margin: 0;
    list-style: none;

    @include mq(medium) {
      flex-flow: row wrap;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      @include mq(medium) {
        width: auto;
      }
    }

    h4 {
      @include type-title-s;

      display: flex;
      gap: rem(8px);
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin: 0;

      .icon {
        transition: all 0.3s ease;

        @include mq(medium) {
          display: none;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: rem(24px);
      max-height: 0;
      padding: 0;
      opacity: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      @include mq(medium) {
        max-height: rem(5000px);
        opacity: 1;
      }

      &.expanded {
        max-height: rem(5000px);
        opacity: 1;
      }

      li {
        gap: unset;

        &:first-child {
          margin: rhythm(base) 0 0;
        }
      }

      a {
        @include type-body-m;

        text-decoration: none;
        border: none;
        margin: 0;

        &:hover {
          text-decoration: underline;
          color: var(--brand-500);
        }
      }
    }

    @include mq(large) {
      padding: rhythm(medium) 0 0;
    }
  }
</style>
